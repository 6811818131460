import { errorMessageHandeling } from '~/helpers';
import $axios from '~/plugins/axios';

export default {
	namespaced: true,
	state: {
		rootLibraryItems: [],
		libraryItems: [],
		folders: [],
		items: [],
		currentLibraryItems: {},
		openFolderId: null,
		itemsLoading: false,

		currentInfo: null,

		libraryPaginator: {},
		folderPagination: {},
		itemsTotalCount: 0,
	},
	getters: {
		rootLibraryItems: state => state.rootLibraryItems,
		libraryItems: state => state.libraryItems,
		currentLibraryItems: state => state.currentLibraryItems,
		currentLibraryFolders: state => id => state.currentLibraryItems[id]?.folders,
		currentLibraryFiles: state => id => state.currentLibraryItems[id]?.items,
		currentInfo: state => state.currentInfo,
		libraryPaginator: state => state.libraryPaginator,
		isActive: state => (id, routeId) => {
			if (id == state.currentLibraryItems[routeId]?.path[0].id) { return true; }
			else { return false; }
		},
	},
	mutations: {
		ITEMS_LOADING (state, isLoading) {
			state.itemsLoading = isLoading;
		},
		SET_ROOT_LIBRARY (state, items) {
			state.rootLibraryItems = items;
		},
		SET_LIBRARY (state, items) {
			state.libraryItems = items;
		},
		ADD_FOLDER (state) {
			let edittingItem = state.rootLibraryItems.folders.find(item => item.editting);
			edittingItem ? edittingItem.editting = false : null;

			state.rootLibraryItems.folders.push({
				name: '',
				editting: true,
			});
		},
		DELETE_FOLDER_NEW_IN_ROOT (state) {
			state.rootLibraryItems.folders.forEach((folder, i) => {
				if (!folder.id) {
					state.rootLibraryItems.folders.splice(i, 1);
				}
			});
		},
		SET_CURRENT_ITEMS (state, { items, id }) {
			state.currentLibraryItems[id] = { folders: items.folders, items: items.items, path: items.path };
		},
		CLEAR_ITEM (state, id) {
			delete state.currentLibraryItems[id].items;
		},
		CLEAR_CURRENT_ITEM (state, id) {
			delete state.currentLibraryItems[id];
		},
		CLEAR_ALL_ITEMS (state) {
			state.currentLibraryItems = {};
		},
		ADD_FOLDER_ITEM_NEW (state, id) {
			state.currentLibraryItems[id].folders.push({ id: null, name: '', isOpen: true, isNew: true, parentFolderId: id });
		},
		DELETE_FOLDER_ITEM_NEW (state) {
			for (const key in state.currentLibraryItems) {
				if (!state.currentLibraryItems.hasOwnProperty(key)) { continue; }

				state.currentLibraryItems[key].folders.forEach((folder, i) => {
					if (!folder.id) {
						state.currentLibraryItems[key].folders.splice(i, 1);
					}
				});
			}
		},
		SET_CURRENT_INFO (state, information) {
			state.currentInfo = information;
		},
		CLEAR_INFORMATION (state, close) {
			let current = state.information.find(item => item.id === state.currentInfo?.id);

			if (current && !close) {
				state.currentInfo.start = current.start;
				state.currentInfo.end = current.end;
				state.currentInfo.content = current.content;
				state.currentInfo.roles = current.roles;
			}
			else { state.currentInfo = null; }
		},
		LIBRARY_PAGINATION (state) {
			let totalCount = state.folderPagination.totalCount + state.itemsTotalCount;
			state.libraryPaginator = {
				totalCount: totalCount,
				selectedCount: state.folderPagination.selectedCount,
			};
		},
		SET_FOLDER_PAGINATION (state, pagination) {
			state.folderPagination = pagination;
		},
		SET_ITEMS_TOTAL_COUNT (state, count) {
			state.itemsTotalCount = count;
		},
		GET_PARENT_FOLDER (state, { item, searchIn }) {
			// TODO refactor this mutation in a more declarative style

			var parentFolderId;
			for (var key in state.currentLibraryItems) {
				if (!state.currentLibraryItems.hasOwnProperty(key)) { continue; }

				// eslint-disable-next-line no-loop-func
				state.currentLibraryItems[key][searchIn].forEach(folder => {
					if (item.id == folder.id) { parentFolderId = key; }
				});
			}
			item.folder = parentFolderId;
		},
	},
	actions: {
		getLibraryFolders ({ commit }) {
			return $axios.get('/library/folder').then(resp => {
				commit('SET_ROOT_LIBRARY', resp.data.payload);
			});
		},

		getLibraryFolderWhole ({ state, commit, dispatch }, { params = {}, pagination = true }) {
			commit('ITEMS_LOADING', true);

			return dispatch('getFolder', params).then(async (foldersResp) => {
				let itemsResp = {}, items = [];
				const folders = foldersResp.data.payload.folders;
				const path = foldersResp.data.payload.path;

				if (!parseInt(params.limit)) {
					itemsResp = await dispatch('getItem', params);
					items = itemsResp.data.payload.items;
					commit('SET_CURRENT_ITEMS', { items: { folders, items, path: itemsResp.data.payload.path }, id: params.id });

					if (pagination) { commit('LIBRARY_PAGINATION'); commit('ITEMS_LOADING', false); }

					return;
				}

				if (parseInt(params.limit) > folders.length) {
					params.limit = parseInt(params.limit) - folders.length;
					if (params.offset) {
						params.offset = params.offset - (state.folderPagination.totalCount - folders.length);
					}
					itemsResp = await dispatch('getItem', params);
					items = itemsResp.data.payload.items;
				}
				else {
					itemsResp = await dispatch('getItem', params);
					items = [];
				}

				commit('SET_CURRENT_ITEMS', { items: { folders, items, path }, id: params.id });

				if (pagination) { commit('LIBRARY_PAGINATION'); commit('ITEMS_LOADING', false); }
			});
		},

		getFolder ({ commit }, params = {}) {
			return $axios.get('/library/folder', { params: params }).then(resp => {
				// commit('SET_CURRENT_ITEMS', { items: resp.data.payload, id: params.id });
				commit('SET_FOLDER_PAGINATION', resp.data.paginator);
				return resp;
			});
		},

		getItem ({ commit }, params = {}) {
			return $axios.get('/library/item', { params: params }).then(resp => {
				// commit('SET_CURRENT_ITEMS', { items: resp.data.payload, id: params.id });
				commit('SET_ITEMS_TOTAL_COUNT', resp.data.paginator.totalCount);
				commit('ITEMS_LOADING', false);
				return resp;
			});
		},

		addFolder ({ dispatch }, { folder, limit, wholeReload = false }) {
			return $axios.post('/library/folder', folder).then(resp => {
				if (wholeReload) {
					if (limit) { dispatch('getLibraryFolderWhole', { params: { id: folder.folder, limit: limit } }); }
					else { dispatch('getLibraryFolderWhole', { params: { id: folder.folder }, pagination: false }); }
					dispatch('getLibraryFolders');
				}
				else if (folder.folder) {
					if (limit) { dispatch('getLibraryFolderWhole', { params: { id: folder.folder, limit: limit } }); }
					else { dispatch('getLibraryFolderWhole', { params: { id: folder.folder }, pagination: false }); }
				}
				else { dispatch('getLibraryFolders'); }

				new TempMessage({
					html: '<p>Složka byla přidána.</p>',
					type: 'success',
				});

				return resp;
			}).catch((err) => {
				new TempMessage({
					html: `<p>Složku se nepodařilo přidat. ${ errorMessageHandeling(err.response.data.code) }</p>`,
					type: 'error',
				});
			});
		},

		addItem ({ dispatch }, { item, limit }) {
			return $axios.post('/library/item', item).then(resp => {
				if (limit) { dispatch('getLibraryFolderWhole', { params: { id: item.folder, limit: limit } }); }
				else { dispatch('getLibraryFolderWhole', { params: { id: item.folder }, pagination: false }); }

				new TempMessage({
					html: '<p>Soubor byl přidán.</p>',
					type: 'success',
				});

				return resp;
			}).catch((err) => {
				new TempMessage({
					html: `<p>Soubor se nepodařilo přidat. ${ errorMessageHandeling(err.response.data.code) }</p>`,
					type: 'error',
				});
			});
		},

		deleteFolder ({ dispatch }, { folder, isRoot = false, limit }) {
			return $axios.delete(`/library/folder/${ folder.id }`).then(resp => {
				if (isRoot) { dispatch('getLibraryFolders'); }
				else if (limit) { dispatch('getLibraryFolderWhole', { params: { id: folder.folder, limit: limit } }); }
				else { dispatch('getLibraryFolderWhole', { params: { id: folder.folder }, pagination: false }); }

				new TempMessage({
					html: '<p>Složka byla odebrána.</p>',
					type: 'success',
				});

				return resp;
			}).catch((err) => {
				new TempMessage({
					html: `<p>Složku se nepodařilo odebrat. ${ errorMessageHandeling(err.response.data.code) }</p>`,
					type: 'error',
				});
			});
		},

		deleteItem ({ dispatch }, { itemId, folderId, limit }) {
			return $axios.delete(`/library/item/${ itemId }`).then(resp => {
				if (limit) { dispatch('getLibraryFolderWhole', { params: { id: folderId, limit: limit } }); }
				else { dispatch('getLibraryFolderWhole', { params: { id: folderId }, pagination: false }); }

				new TempMessage({
					html: '<p>Soubor byl odebrán.</p>',
					type: 'success',
				});

				return resp;
			}).catch((err) => {
				new TempMessage({
					html: `<p>Soubor se nepodařilo odebrat. ${ errorMessageHandeling(err.response.data.code) }</p>`,
					type: 'error',
				});
			});
		},

		editFolder ({ state, dispatch }, { folder, limit }) {
			// TODO refactor the following fragment od code in a more declarative style
			var parentFolderId;
			for (var key in state.currentLibraryItems) {
				if (!state.currentLibraryItems.hasOwnProperty(key)) { continue; }
				// eslint-disable-next-line no-loop-func
				state.currentLibraryItems[key].folders.forEach(item => {
					if (item.id == folder.id) { parentFolderId = key; }
				});
			}
			folder.folder = parentFolderId;

			return $axios.put(`/library/folder/${ folder.id }`, folder).then(resp => {
				if (folder.folder) { dispatch('getLibraryFolderWhole', { params: { id: folder.folder, limit: limit } }); }
				else { dispatch('getLibraryFolders'); }

				new TempMessage({
					html: '<p>Složka byla upravena.</p>',
					type: 'success',
				});

				return resp;
			}).catch((err) => {
				new TempMessage({
					html: `<p>Složku se nepodařilo uložit. ${ errorMessageHandeling(err.response.data.code) }</p>`,
					type: 'error',
				});
			});
		},
	},
};

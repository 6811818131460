document.addEventListener('DOMContentLoaded', function () {
	document.querySelectorAll('a[data-confirm], a[data-datagrid-confirm]:not(.ajax)').forEach(confirmEl => {
		confirmEl.addEventListener('click', (e) => {
			e.preventDefault();
			const targetEl = e.target.closest('a');
			const message = targetEl.dataset.confirm || targetEl.dataset.datagridConfirm || 'Opravdu si přejete smazat položku?';
			window.popup({
				type: 'message',
				text: message,
				confirmBtn: {
					text: 'OK',
					callback: function () {
						window.location.href = targetEl.href;
					},
				},
				cancelBtn: {
					text: 'Zrušit',
				},
			});
		});
	});
});

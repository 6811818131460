/*

Popup funkcionalita

Priklady pouziti:

window.popup({
    type: 'image',
    url: 'URL'
});

window.popup({
    type: 'confirm/warning',
    text: 'HTML',
    confirmBtn: {
        text: 'Odeslat',
        callback: function () {}
    },
    cancelBtn: {
        text: 'Zrušit',
        callback: function () {}
    }
});

window.popup({
    type: 'message',
    text: 'HTML',
    confirmBtn: {
        text: 'OK'
    }
});

*/


(function () {

	var popupObj = [];

	// Docasna funkce pro vlozeni popupu do fronty, nez se inicializuje funkcionalita
	window.popup = function (obj) {
		popupObj.push(obj);
	};

	// Funkce pro zobrazeni popupu, ktere cekaji
	function checkPopupQueue () {
		if (popupObj.length > 0) {
			p.show(popupObj[0]);
			popupObj = popupObj.slice(1, popupObj.length);
		}
	}

	// Objekt s metodami pro zobrazeni popupu
	var p = {

		// Mozne typy bubliny
		types: [ 'image', 'grid', 'comment', 'message', 'confirm', 'warning' ],

		// #popup
		box: null,

		// #popup-content
		contentBox: null,

		// #popup-buttons a.confirm
		confirmBtn: null,

		// = custom callback dodany v configu
		confirmBtnCallback: function () {
			// nic nedelat
		},

		// #popup-buttons a.cancel
		cancelBtn: null,

		// = custom callback dodany v configu
		cancelBtnCallback: function () {
			// nic nedelat
		},

		// Inicializace popupu
		init: function () {
			p.box = document.getElementById('popup');
			p.contentBox = document.getElementById('popup-content');
			p.confirmBtn = p.box.querySelector('a.confirm');
			p.cancelBtn = p.box.querySelector('a.cancel');

			p.box.addEventListener('click', p.hide);
			document.querySelector('#popup > div').addEventListener('click', function (e) {
				e.stopPropagation();
			});

			document.querySelector('#popup .header .close').addEventListener('click', function (e) {
				e.preventDefault();
				p.hide();
			});

			p.confirmBtn.addEventListener('click', function (e) {
				e.preventDefault();
				p.confirmBtnCallback();
				p.hide();
			});
			p.cancelBtn.addEventListener('click', function (e) {
				e.preventDefault();
				p.cancelBtnCallback();
				p.hide();
			});

			// Dodani funkcionality do window
			window.popup = p.show;

			// Zobrazeni popupu, ktere cekaji
			checkPopupQueue();
		},

		// Zobrazeni popupu
		show: function (config) {
			if (p.types.indexOf(config.type) == -1) {
				// console.warn('Nepodporovaný typ bubliny');
				return;
			}

			for (var i = 0; i < p.types.length; i++) {
				p.box.classList.remove(p.types[i]);
			}
			p.confirmBtn.classList.remove('show');
			p.cancelBtn.classList.remove('show');

			if (config.type == 'image') {
				if (config.url == location.href || config.url == location.href + '#') {
					config.type = 'message';
					p.contentBox.innerHTML = 'Obrázek chybí';
				}
				else {
					p.contentBox.innerHTML = '<img src="' + config.url + '" alt="" />';
				}
			}
			else if (config.type === 'grid') {
				document.querySelectorAll('.checkbox').forEach(el => { el.classList.remove('hidden'); });

				p.contentBox.innerHTML = config.text;
			}
			else {
				p.contentBox.innerHTML = config.text;
			}

			p.box.classList.add(config.type);

			if (config.confirmBtn) {
				p.confirmBtn.classList.add('show');
				p.confirmBtn.innerHTML = config.confirmBtn.text;

				if (config.confirmBtn.callback && typeof config.confirmBtn.callback === 'function') {
					p.confirmBtnCallback = config.confirmBtn.callback;
				}
			}
			if (config.cancelBtn) {
				p.cancelBtn.classList.add('show');
				p.cancelBtn.innerHTML = config.cancelBtn.text;

				if (config.cancelBtn.callback && typeof config.cancelBtn.callback === 'function') {
					p.cancelBtnCallback = config.cancelBtn.callback;
				}
			}

			p.box.classList.add('active');
			document.body.classList.add('no-scroll');
			window.addEventListener('keyup', p.keyControl);
		},

		// Skryti popupu
		hide: function () {
			p.box.classList.remove('active');
			document.body.classList.remove('no-scroll');
			window.removeEventListener('keyup', p.keyControl);

			p.confirmBtnCallback = function () {
				// nic nedelat
			};
			p.cancelBtnCallback = function () {
				// nic nedelat
			};

			// Zobrazeni popupu, ktere cekaji
			checkPopupQueue();
		},

		// Ovladani klavesnici
		keyControl: function (e) {
			var key = e.which || e.keyCode || 0;
			if (key == 27) { p.hide(); }
		},

	};


	// Inicializace popup funkcionality
	document.addEventListener('DOMContentLoaded', p.init);
})();

import $axios from '~/plugins/axios';

import { Api, GetProductTypeParams, ProductType } from '~/api';
import { buildModule } from '../utils';
import { createDict, Dict } from '~/helpers';

const api = $axios as Api;

type LazyLoadingParams = Omit<GetProductTypeParams, 'limit' | 'offset'>;

export default buildModule()
	.withState({
		productTypeById: {} as Dict<ProductType>,
		orderedIds: [] as number[],
		params: {} as LazyLoadingParams,
	})
	.withGetters({
		items: (state) => state.orderedIds.map(id => state.productTypeById[id]),
		byId: (state) => (id: number) => state.productTypeById[id],
	})
	.withMutations({
		RESET (state, params: LazyLoadingParams = {}) {
			state.params = params;
			state.orderedIds = [];
		},
		APPEND_PRODUCT_TYPES (state, productTypes: ProductType[]) {
			state.productTypeById = { ...state.productTypeById, ...createDict(productTypes, ({ id }) => id) };
			state.orderedIds = Array.from(new Set([ ...state.orderedIds, ...productTypes.map((pos) => pos.id) ]));
		},
		SET_PRODUCT_TYPES (state, productTypes: ProductType[]) {
			state.productTypeById = { ...state.productTypeById, ...createDict(productTypes, ({ id }) => id) };
		},
	})
	.withActions({
		async getMore ({ state, commit }, limit: number | null = 20) {
			const params: GetProductTypeParams = { ...state.params, limit, offset: state.orderedIds.length };

			if (params.searchTerm != null && params.searchTerm.length < 3) {
				params.searchTerm = undefined;
			}

			const { data } = await api.get('/product-type', { params });

			commit('APPEND_PRODUCT_TYPES', data.payload);
		},
		async prefetchProductTypes ({ commit }, productTypeIds: number[]) {
			const productTypeGetRequests = productTypeIds.map((id) => api.get(`/product-type/${ id }`));
			const productTypeResponses = await Promise.all(productTypeGetRequests);

			const productTypes = productTypeResponses.map((resp) => resp.data.payload);
			commit('SET_PRODUCT_TYPES', productTypes);
		},
		async getProductType ({ commit }, productTypeId: number) {
			const { data } = await api.get(`/product-type/${ productTypeId }`);

			commit('SET_PRODUCT_TYPES', [ data.payload ]);
		},
		resetParams ({ state, commit }, params: LazyLoadingParams = {}) {
			commit('RESET', { ...state.params, ...params });
		},
	});

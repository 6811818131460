import $axios from '~/plugins/axios';
import { errorMessageHandeling } from '~/helpers';
import { v4 as uuidv4 } from 'uuid';

export default {
	namespaced: true,
	state: {
		protocols: [],
		protocolsOpened: [],
		protocolsNew: [],
		auditItems: {},
		selectedAuditDetail: {},
		questionTypes: [
			{
				text: 'Jedna odpověď',
				value: 'radiolist',
			},
			{
				text: 'Více odpovědí',
				value: 'checkboxlist',
			},
			{
				text: 'Volná odpověď',
				value: 'text',
			},
			{
				text: 'Číslo',
				value: 'number',
			},
			{
				text: 'Škála',
				value: 'range',
			},
			{
				text: 'Fotka',
				value: 'image',
			},
		],
		variableTypes: [
			{
				text: 'Dynamická',
				value: 'dynamic',
			},
			{
				text: 'Text',
				value: 'text',
			},
			{
				text: 'Zaškrtávácí pole',
				value: 'checkbox',
			},
			{
				text: 'Číslo',
				value: 'number',
			},
			{
				text: 'Výběr ze seznamu možností',
				value: 'radio',
			},
			{
				text: 'Podpis',
				value: 'signature',
			},
		],
		variableDynamicTypes: [],
		protocolTypes: [
			{
				label: 'Předání',
				value: 'handover',
			},
			{
				label: 'Vrácení',
				value: 'return',
			},
			{
				label: 'Ostatní',
				value: 'other',
			},
		],
		editingVariable: {},
	},
	getters: {
		protocols: state => state.protocols,
		protocolsNew: state => state.protocolsNew,
		protocolDetail: state => id => state.protocolsOpened.find(protocol => protocol.id === id) || null,
		variableTypes: state => state.variableTypes,
		protocolTypes: state => state.protocolTypes,
		variableDynamicTypes: state => state.variableDynamicTypes,
		templateVariables: (state, getters) => (protocolId) => {
			const thisProtocol = getters.protocolDetail(protocolId);
			return thisProtocol.variables;
		},
		templateVariable: (state, getters) => ({ protocolId, variableUuid }) => {
			const thisProtocol = getters.protocolDetail(protocolId);
			const variableIndex = thisProtocol.variables.findIndex(variable => variable.uuid === variableUuid);
			return thisProtocol.variables[variableIndex];
		},
		auditItems: state => protocolId => state.auditItems[protocolId] ?? [],
		selectedAuditDetail: state => protocolId => state.selectedAuditDetail[protocolId] ?? null,
	},
	mutations: {
		SET_PROTOCOLS (state, protocols) {
			state.protocols = protocols;
		},
		PROTOCOL_OPEN (state, protocol) {
			const { roles: roles, audit: audit, ...rest } = protocol;
			state.protocolsOpened = [
				...state.protocolsOpened,
				{
					...rest,
					auditId: audit?.id ?? null,
					roleId: roles.map(({ id }) => id),
				},
			];
		},
		PROTOCOL_ADD_NEW (state) {
			const PROTOCOL_DEFAULT = {
				id: null,
				name: 'Nový protokol',
				htmlContent: '',
				start: null,
				end: null,
				description: null,
				roleId: [],
				published: true,
				variables: [],
			};
			state.protocolsNew.push(PROTOCOL_DEFAULT);
			state.protocolsOpened.push(PROTOCOL_DEFAULT);
		},
		PROTOCOL_ADD_DUPLICATE (state, protocol) {
			state.protocolsNew.push(protocol);
			state.protocolsOpened.push(protocol);
		},
		PROTOCOL_DELETE (state, id) {
			const protocolIndex = state.protocolsOpened.findIndex(protocol => protocol.id === id);
			state.protocolsOpened.splice(protocolIndex, 1);
		},
		PROTOCOL_DELETE_NEW (state, index) {
			state.protocolsNew.splice(index, 1);
			const protocolIndex = state.protocolsOpened.findIndex(protocol => protocol.id === null);
			state.protocolsOpened.splice(protocolIndex, 1);
		},
		PROTOCOL_UPDATE_FIELD (state, { id, property, value }) {
			const protocolIndex = state.protocolsOpened.findIndex(protocol => protocol.id === id);
			if (protocolIndex === -1) {
				return;
			}
			state.protocolsOpened[protocolIndex][property] = value;
		},
		TEMPLATE_VARIABLE_ADD (state, { protocolId, variableData }) {
			const protocolIndex = state.protocolsOpened.findIndex(protocol => protocol.id === protocolId);
			const thisProtocol = state.protocolsOpened[protocolIndex];
			thisProtocol.variables.push(variableData);
		},
		TEMPLATE_VARIABLE_UPDATE (state, { protocolId, variableData }) {
			const protocolIndex = state.protocolsOpened.findIndex(protocol => protocol.id === protocolId);
			const thisProtocol = state.protocolsOpened[protocolIndex];
			const thisVariableIndex = thisProtocol.variables.findIndex(templateVariable => templateVariable.uuid === variableData.uuid);
			thisProtocol.variables[thisVariableIndex] = { ...variableData };
		},
		TEMPLATE_EDITING_VARIABLE_UPDATE (state, variableData) {
			state.editingVariable = variableData;
		},
		TEMPLATE_VARIABLE_UPDATE_FIELD (state, { protocolId, variableUuid, variableProperty, variableValue }) {
			const protocolIndex = state.protocolsOpened.findIndex(protocol => protocol.id === protocolId);
			const thisProtocol = state.protocolsOpened[protocolIndex];
			const thisVariableIndex = thisProtocol.variables.findIndex(templateVariable => templateVariable.uuid === variableUuid);
			thisProtocol.variables[thisVariableIndex][variableProperty] = variableValue;
		},
		TEMPLATE_VARIABLE_DELETE (state, { protocolId, variableUuid }) {
			const protocolIndex = state.protocolsOpened.findIndex(protocol => protocol.id === protocolId);
			const thisProtocol = state.protocolsOpened[protocolIndex];
			const thisVariableIndex = thisProtocol.variables.findIndex(templateVariable => templateVariable.uuid === variableUuid);
			thisProtocol.variables.splice(thisVariableIndex, 1);
		},
		TEMPLATE_DYNAMIC_TYPES (state, variableData) {
			state.variableDynamicTypes = variableData;
		},
		SET_AUDIT_ITEMS (state, { protocolId, auditItems }) {
			state.auditItems = {
				...state.auditItems,
				[protocolId]: auditItems,
			};
		},
		SET_SELECTED_AUDIT_DETAIL (state, { protocolId, auditDetail }) {
			state.selectedAuditDetail = {
				...state.selectedAuditDetail,
				[protocolId]: auditDetail,
			};
		},
	},
	actions: {
		getProtocols ({ commit }, { inactive = true } = {}) {
			const params = {};
			if (inactive) {
				params.inactive = true;
			}

			return $axios.get('/protocol', { params }).then(resp => {
				commit('SET_PROTOCOLS', resp.data.payload);
			});
		},
		getProtocol ({ commit }, { id }) {
			return $axios.get(`/protocol/${ id }`, { params: { inactive: true } }).then(resp => {
				commit('PROTOCOL_OPEN', resp.data.payload);
			});
		},
		getDynamicTypes ({ commit }) {
			return $axios.get('/protocol-variable/dynamic-type').then(resp => {
				commit('TEMPLATE_DYNAMIC_TYPES', resp.data.payload);
			});
		},
		addProtocol ({ dispatch }, protocol) {
			return $axios.post('/protocol', protocol).then(resp => {
				dispatch('getProtocols');

				new TempMessage({
					html: '<p>Protokol byl přidán.</p>',
					type: 'success',
				});

				return resp;
			}).catch((err) => {
				new TempMessage({
					html: `<p>Protokol se nepodařilo uložit. ${ errorMessageHandeling(err.response.data.code) }</p>`,
					type: 'error',
				});
			});
		},
		duplicateProtocol ({ commit }, protocol) {
			const duplicatedProtocol = JSON.parse(JSON.stringify(protocol));
			duplicatedProtocol.id = null;
			duplicatedProtocol.name = `Kopie - ${ duplicatedProtocol.name }`;
			duplicatedProtocol.variables = duplicatedProtocol.variables.filter(({ uuid }) => duplicatedProtocol.htmlContent.includes(uuid));
			duplicatedProtocol.variables.forEach(duplicatedVariable => {
				const oldUuid = duplicatedVariable.uuid;
				duplicatedVariable.uuid = uuidv4();
				duplicatedVariable.id = null;
				duplicatedProtocol.htmlContent = duplicatedProtocol.htmlContent.replaceAll(oldUuid, duplicatedVariable.uuid);
			});
			commit('PROTOCOL_ADD_DUPLICATE', duplicatedProtocol);
		},
		updateProtocol ({ commit, dispatch }, protocol) {
			return $axios.put(`/protocol/${ protocol.id }`, protocol).then(resp => {
				commit('PROTOCOL_DELETE', protocol.id);
				dispatch('getProtocols');

				new TempMessage({
					html: '<p>Protokol byl upraven.</p>',
					type: 'success',
				});

				return Promise.resolve(resp);
			}).catch((err) => {
				new TempMessage({
					html: `<p>Protokol se nepodařilo uložit. ${ errorMessageHandeling(err.response.data.code) }</p>`,
					type: 'error',
				});
				return Promise.reject(err);
			});
		},
		deleteProtocol ({ dispatch }, protocolId) {
			return $axios.delete(`/protocol/${ protocolId }`).then(resp => {
				dispatch('getProtocols');

				new TempMessage({
					html: '<p>Protokol byl odebrán.</p>',
					type: 'success',
				});

				return resp;
			}).catch((err) => {
				new TempMessage({
					html: `<p>Protokol se nepodařilo odebrat. ${ errorMessageHandeling(err.response.data.code) }</p>`,
					type: 'error',
				});
			});
		},
		createVariable ({ commit }, { protocolId }) {
			const newUuid = uuidv4();
			return new Promise((resolve) => {
				commit('TEMPLATE_VARIABLE_ADD', {
					protocolId,
					variableData: {
						uuid: newUuid,
						type: '',
						name: '',
						required: false,
						multiple: false,
						options: [],
						autoFill: false,
						dynamicType: null,
					},
				});
				resolve(newUuid);
			});
		},
		async getAuditItems ({ commit }, { auditId, protocolId }) {
			try {
				const resp = await $axios.get(`/protocol-variable/audit/${ auditId }`);
				commit('SET_AUDIT_ITEMS', { protocolId, auditItems: resp?.data?.payload ?? [] });
			}
			catch {
				commit('SET_AUDIT_ITEMS', { protocolId, auditItems: [] });
			}
		},
		async getAuditDetail ({ commit }, { auditId, protocolId }) {
			try {
				const resp = await $axios.get(`/audit/${ auditId }`, { params: { inactive: true } });
				commit('SET_SELECTED_AUDIT_DETAIL', { protocolId, auditDetail: resp?.data?.payload ?? null });
			}
			catch {
				commit('SET_SELECTED_AUDIT_DETAIL', { protocolId, auditDetail: null });
			}
		},
	},
};

import { Store } from 'vuex';
import router from '../router';
import { buildModule, createRouteModule, createRouteSyncPlugin, ModuleInterface, ModuleState } from './utils';
import admins from './admins';
import areas from './areas';
import core from './core';
import planner from './planner';
import user from './user';
import audits from './audits';
import poses from './poses';
import productTypes from './product-types';
import protocols from './protocols';
import dashboard from './dashboard';
import library from './library';
import information from './information';
import notifications from './notifications';
import regions from './regions';
import settings from './settings';
import tasks from './tasks';
import territories from './territories';
import abilityPlugin from './plugins/ability';

const rootModule = buildModule()
	.withSubmodules({
		...createRouteModule(router),
		admins,
		areas,
		core,
		planner,
		user,
		audits,
		dashboard,
		library,
		information,
		notifications,
		poses,
		productTypes,
		protocols,
		regions,
		settings,
		tasks,
		territories,
	});

export type { UntypedStore } from './utils';
export type TypedStore = ModuleInterface<typeof rootModule>;
export type GetterValue<P extends keyof TypedStore['getters']> = TypedStore['getters'][P];

type TypedState = ModuleState<typeof rootModule>;

const store = createStore({
	...rootModule,
	state: {} as TypedState,
	plugins: [
		createRouteSyncPlugin(router),
		abilityPlugin,
	],
	// strict: true,
});

export default store as Omit<Store<TypedState>, keyof TypedStore> & TypedStore;

import $axios from '~/plugins/axios';
import { Api, GetRoleLooseParams, GetRoleParams, RoleLight } from '~/api';
import { buildModule } from '../utils';

const api = $axios as Api;

export default buildModule()
	.withState({
		roles: [] as RoleLight[],
		rolesLoose: [] as RoleLight[],
	})
	.withGetters({
		roles: (state) => state.roles,
		rolesLoose: (state) => state.rolesLoose,
	})
	.withMutations({
		ROLES_SET (state, roles: RoleLight[]) {
			state.roles = roles;
		},
		ROLES_LOOSE_SET (state, rolesLoose: RoleLight[]) {
			state.rolesLoose = rolesLoose;
		},
	})
	.withActions({
		getRoles ({ commit }, params: GetRoleParams = {}) {
			return api.get('/role', { params })
				.then(resp => {
					const roles = resp.data.payload;
					commit('ROLES_SET', roles);
					return resp;
				})
				.catch(err => {
					new TempMessage({
						html: '<p>Role se nepodařilo načíst.</p>',
						type: 'error',
					});
					return err;
				});
		},
		getRolesLoose ({ commit }, params: GetRoleLooseParams = {}) {
			return api.get('/role/loose', { params })
				.then(resp => {
					const roles = resp.data.payload;
					commit('ROLES_LOOSE_SET', roles);
					return resp;
				})
				.catch(err => {
					new TempMessage({
						html: '<p>Role se nepodařilo načíst.</p>',
						type: 'error',
					});
					return err;
				});
		},
	});

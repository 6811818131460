import moment from 'moment';
import { EventApi } from '@fullcalendar/vue3';
import { PaginatorResult } from '~/api';
import { Dict } from './dict';
import { Infer, assert, number, string, type } from 'superstruct';
import langs from './langs.json';

// TODO remove when planner is no longer dependent on Fullcalendar's data structures
type DeeplyPartial<T> = T extends {} ? { [K in keyof T]?: DeeplyPartial<T[K]> } : T;
export type FullcalendarEvent = DeeplyPartial<EventApi>;

const ApiError = type({
	response: type({
		data: type({
			code: number(),
			message: string(),
		}),
	}),
});

export type ApiError = Infer<typeof ApiError>;

export function assertApiError (value: unknown): asserts value is ApiError {
	assert(value, ApiError);
}

export function barColor (value: number): string {
	if (value < 30) { return 'rgb(219, 0, 51)'; }
	else if (value < 50) { return '#ffba00'; }
	else if (value < 75) { return 'rgb(106, 164, 231)'; }
	else if (value < 90) { return 'rgb(15, 50, 235)'; }
	else { return '#2ed00d'; }
}

// FUNCTION IN PROGRESS
export function errorMessageHandeling (errorCode: number): string {
	if (errorCode === 422) { return 'Zkuste prosím upravit data ve formuláři.'; }
	else { return ''; }
}

export function toArray<T> (value: T | T[]): T[] {
	if (!Array.isArray(value)) {
		return (value || value === 0) ? [ value ] : [];
	}

	return value;
}

export function mod (x: number, modulus: number): number {
	return ((x % modulus) + modulus) % modulus;
}

export function toPercentage (fraction: number): string {
	return `${ (fraction * 100).toFixed(2) }%`;
}

export function prettyFormatDecimal (value: number): string {
	return value.toFixed(2).replace(/\.?0*$/, '');
}

export function capitalize (text: string): string {
	return `${ text[0]?.toUpperCase() ?? '' }${ text.slice(1) }`;
}

export function deepEqual (a: unknown, b: unknown): boolean {
	if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
		return a === b;
	}

	const dictA: Partial<Dict<unknown>> = a;
	const dictB: Partial<Dict<unknown>> = b;
	const keysA = Object.keys(a);
	const keysB = Object.keys(b);
	return (
		keysA.length === keysB.length &&
		keysA.every(key => deepEqual(dictA[key], dictB[key]))
	);
}

export function getDaysBetweenDates (startDate: string, endDate: string): string[] {
	const now = moment(startDate);
	const dates = [];

	while (now.isSameOrBefore(endDate)) {
		dates.push(now.format('YYYY-MM-DD'));
		now.add(1, 'days');
	}
	return dates;
}

export function isEmpty (value: unknown): boolean {
	return (
		value === '' ||
		value === null ||
		value === undefined ||
		(Array.isArray(value) && value.length === 0)
	);
}

export function omitEmpty<T extends {}> (dict: Dict<T | undefined | null>): Dict<T> {
	const entries = Object
		.entries(dict)
		.filter((entry): entry is [string, T] => !isEmpty(entry[1]));

	return Object.fromEntries(entries);
}

export function pickFrom<T extends {}, K extends keyof T> (object: T, keys: K[]): Pick<T, K> {
	const [ firstKey, ...otherKeys ] = keys;
	if (firstKey == null) {
		return {} as Pick<T, K>;
	}
	return { [firstKey]: object[firstKey], ...pickFrom(object, otherKeys) };
}

export function omitFrom<T extends {}, K extends keyof T> (object: T, keys: K[]): Omit<T, K> {
	const [ firstKey, ...otherKeys ] = keys;
	if (firstKey == null) {
		return object;
	}

	const { [firstKey]: _, ...rest } = omitFrom(object, otherKeys);
	return rest as Omit<T, K>;
}

export function assertNonNullish<T extends {}> (value: T | undefined | null): asserts value is T {
	if (value == null) {
		throw Error(`Value is ${ value }`);
	}
}

export function isOneOf<S extends string> (known: S[], value: string): value is S {
	return (known as string[]).includes(value);
}

export function keysOf<T extends {}> (obj: T): (string & keyof T)[] {
	return Object.keys(obj) as (string & keyof T)[];
}

export function containSameItems<T> (
	iterable1: Iterable<T>,
	iterable2: Iterable<T>,
): boolean {
	const array = [ ...iterable1 ];
	const set = new Set(iterable2);
	return (
		array.length === set.size &&
		array.every(item => set.has(item))
	);
}

export function includesInsensitive (value: string, substring: string): boolean {
	return value.toLowerCase().includes(substring.toLowerCase());
}

export function cacheUntilDistinct<T> (areEqual: (newValue: T, cachedValue: T) => boolean): (newValue: T) => T {
	let cachedValue: T;

	return newValue => {
		if (!areEqual(newValue, cachedValue)) {
			cachedValue = newValue;
		}
		return cachedValue;
	};
}

export function range (from: number, to: number, step = 1): number[] {
	return [ ...Array(Math.ceil(Math.max(to - from, 0) / step)) ]
		.map((_, i) => (i * step) + from);
}

export function sum (acc: number, item: number) {
	return acc + item;
}

export function cumulativeSum<T> (itemValue: (item: T, index: number) => number): (
	(acc: [T, number][], item: T, index: number) => [T, number][]
) {
	return (acc, item, i) => {
		const previousSum = acc.at(-1)?.[1] ?? 0;
		return [ ...acc, [ item, previousSum + itemValue(item, i) ] ];
	};
}

export function fetchAllPages<
	P extends string,
	Q extends {
		limit?: number | null;
		offset?: number | null;
	}
> (path: P, queryParams: Q, limit = 200): <R>(api: {
	get(path: P, config: { params: Q }): Promise<{
		data: { payload: R[]; paginator: PaginatorResult };
	}>;
}) => Promise<R[]> {
	return async api => {
		const fetchWithOffset = (offset: number) => (
			api.get(path, { params: { ...queryParams, limit, offset } })
		);

		const firstResponse = await fetchWithOffset(0);
		const { payload, paginator: { totalCount } } = firstResponse.data;
		const requests = range(limit, totalCount, limit).map(fetchWithOffset);
		const responses = await Promise.all(requests);
		return [ ...payload, ...responses.flatMap(({ data }) => data.payload) ];
	};
}

export function triggerDownload (url: string): void {
	const link = document.createElement('a');
	link.href = url;
	link.download = '';
	link.click();
}

export function nullify<T extends { length?: unknown } | null | undefined> (value: T): T | null {
	return (value && value.length) ? value : null;
}

export function not<
	T extends unknown[],
> (predicate: (...args: T) => boolean): (...args: T) => boolean {
	return (...args) => !predicate(...args);
}

export function isMidnight (input: moment.MomentInput): boolean {
	const dateTime = moment(input);
	return dateTime.isSame(dateTime.clone().startOf('day'));
}

export function randomHex (length: number): string {
	return Array.from({ length }, () => Math.floor(Math.random() * 16).toString(16)).join('');
}

export function pluckId (object: { id: number }): number {
	return object.id;
}

export type Pair<T, U> = {
    firstValue: {
        arrayIndex: number;
        value: T;
    };
    secondValue: {
        arrayIndex: number;
        value: U;
    };
};

export function getPairedArray<T> (objectArray: T[]): Pair<T, T | null>[] {
	return objectArray
		.map((value, index) => {
			const nextIndex = index + 1;
			const nextValue = nextIndex < objectArray.length
				? objectArray[nextIndex]
				: null;
			return {
				firstValue: {
					arrayIndex: index,
					value: value,
				},
				secondValue: {
					arrayIndex: nextIndex,
					value: nextValue,
				},
			};
		})
		.filter((_, index) => index % 2 === 0);
}

export function isObject (value: unknown): boolean {
	return value !== null && !Array.isArray(value) && typeof value === 'object';
}

export function getRawData<T> (data: T): T {
	return isReactive(data) ? toRaw(data) : data;
}

export function toDeepRaw<T> (data: T): T {
	const rawData = getRawData<T>(data);

	for (const key in rawData) {
		if (Object.hasOwn(rawData as object, key)) {
			const value = rawData[key];

			if (!isObject(value) && !Array.isArray(value)) {
				continue;
			}

			rawData[key] = toDeepRaw<typeof value>(value);
		}
	}

	return rawData;
}

export type LanguageInfo = {
	key: string;
	nativeName: string;
	englishName: string;
};

export function getLanguageInfoByIdentifier (identifier: string): LanguageInfo | null {
	const languages: Record<string, { nativeName: string; englishName: string }> = langs;
	const languageInfo = languages[identifier] || null;

	return languageInfo != null
		? { key: identifier, ...languageInfo }
		: null;
}

export function loggedInAsAnotherAdmin (): boolean {
	return 'asAnotherAdmin' in document.body.dataset;
}

import { t } from 'i18next';
import { TaskTypeStatus } from '~/api';
import { keysOf } from './common';

type StateOptionLabel = Record<TaskTypeStatus['state'], string>;

export const TASK_STATE_LABELS: StateOptionLabel = {
	todo: t('Nevyřešeno'),
	// eslint-disable-next-line camelcase
	in_progress: t('Probíhá'),
	done: t('Hotovo'),
};

export const TASK_STATES = keysOf(TASK_STATE_LABELS);

export const TASK_STATE_ITEMS = TASK_STATES.map(id => ({ id, name: TASK_STATE_LABELS[id] }));

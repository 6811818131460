import dragula from 'dragula';
import { removeErrorMessage } from './forms';
import Choices from 'choices.js';

// Zakladni informace
document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('branch-map')) { return; }

	window.initMap = function () {

		// Styly mapy
		var mapStyles = {
			fullscreen: [],
			default: [
				{
					featureType: 'administrative',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#686868',
						},
					],
				},
				{
					featureType: 'administrative',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#ffffff',
						},
					],
				},
				{
					featureType: 'landscape',
					elementType: 'all',
					stylers: [
						{
							color: '#f9f9f9',
						},
					],
				},
				{
					featureType: 'poi',
					elementType: 'all',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'all',
					stylers: [
						{
							color: '#dddddd',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#686868',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#ffffff',
						},
						{
							visibility: 'on',
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'transit',
					elementType: 'all',
					stylers: [
						{
							visibility: 'simplified',
						},
						{
							color: '#dddddd',
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'all',
					stylers: [
						{
							color: '#c2e9f9',
						},
						{
							visibility: 'on',
						},
					],
				},
			],
		};

		// Styly markeru
		var markerIcon = {
			fullscreen: {
				path: 'M11 2c-3.9 0-7 3.1-7 7 0 5.3 7 13 7 13 0 0 7-7.7 7-13 0-3.9-3.1-7-7-7Zm0 9.5c-1.4 0-2.5-1.1-2.5-2.5 0-1.4 1.1-2.5 2.5-2.5 1.4 0 2.5 1.1 2.5 2.5 0 1.4-1.1 2.5-2.5 2.5Z',
				scale: 3,
				anchor: new google.maps.Point(11, 22),
				fillOpacity: 1,
				fillColor: '#353535',
				strokeOpacity: 1,
				strokeColor: '#ffffff',
				strokeWeight: 2,
			},
			default: {
				path: 'M11 2c-3.9 0-7 3.1-7 7 0 5.3 7 13 7 13 0 0 7-7.7 7-13 0-3.9-3.1-7-7-7Zm0 9.5c-1.4 0-2.5-1.1-2.5-2.5 0-1.4 1.1-2.5 2.5-2.5 1.4 0 2.5 1.1 2.5 2.5 0 1.4-1.1 2.5-2.5 2.5Z',
				scale: 2.3,
				anchor: new google.maps.Point(11, 22),
				fillOpacity: 1,
				fillColor: '#0F32EB',
				strokeOpacity: 0,
				strokeColor: '#ffffff',
				strokeWeight: 0,
			},
		};

		// Inicializace mapy a markeru
		var map, marker;

		function init (location) {

			// Vytvoreni mapy
			map = new google.maps.Map(document.getElementById('branch-map'), {
				center: location,
				zoom: 14,
				disableDefaultUI: true,
				zoomControl: true,
				fullscreenControl: true,
				styles: mapStyles.default,
			});

			// Vytvoreni markeru
			marker = new google.maps.Marker({
				position: location,
				map: map,
				title: 'Umístění pobočky',
				icon: markerIcon.default,
			});

			// Zmena stylu pri fullscreen mape a zpet
			google.maps.event.addListener(map, 'bounds_changed', function () {
				var mapSize = map.getDiv().firstChild.getBoundingClientRect();

				// Fullscreen
				if (mapSize.height == window.innerHeight && mapSize.width == window.innerWidth) {
					map.setOptions({
						styles: mapStyles.fullscreen,
					});
					marker.setIcon(markerIcon.fullscreen);
				}

				// Default
				else {
					map.setOptions({
						styles: mapStyles.default,
					});
					marker.setIcon(markerIcon.default);
				}
			});
		}

		// Zmena mapy na novou adresu
		function changeLocation (location) {

			// Pokud mapa jeste neexistuje, inicializovat
			if (!map) { init(); }

			// Zmenit adresu
			map.setOptions({
				center: location,
			});
			marker.setPosition(location);
		}

		// Ziskani souradnic z adresy pobocky
		var geocoder;

		function geocodeAddress (address, callback) {
			geocoder = new google.maps.Geocoder();
			geocoder.geocode({
				address: address,
			}, function (results, status) {
				if (status != google.maps.GeocoderStatus.OK) { return; }
				if (document.getElementById('branch-map').getAttribute('data-address') != address) { return; } // Stary zpozdeny request, jiz je nova adresa

				switch (callback) {
				case 'changeLocation': changeLocation(results[0].geometry.location); break;
				case 'changeGPS': changeGPS(results[0].geometry.location); break;
				}
			});
		}

		// Zpracovat adresu
		if (document.getElementById('branch-map').getAttribute('data-gps')) {
			var gps = document.getElementById('branch-map').getAttribute('data-gps').split(',');

			changeLocation({
				lat: parseFloat(gps[0].trim()),
				lng: parseFloat(gps[1].trim()),
			});
		}
		else {
			geocodeAddress(document.getElementById('branch-map').getAttribute('data-address'), 'changeLocation');
		}

		// Listener na dynamickou zmenu adresy
		document.getElementById('branch-map').addEventListener('changeAddress', function () {
			var container = document.getElementById('branch-address');

			// Ziskat hodnoty z inputu
			var inputFields = {
				street: '',
				'street-number': '',
				city: '',
				'postal-code': '',
				latitude: '',
				longitude: '',
			};

			for (var type in inputFields) {
				if (inputFields.hasOwnProperty(type)) {
					inputFields[type] = container.querySelector('input[data-type="' + type + '"]').value;
				}
			}

			// Zformatovat adresu dle toho, co je vyplnene
			var address = '';

			if (inputFields.street) {
				address = inputFields.street + (inputFields['street-number'] ? ' ' + inputFields['street-number'] : '');
			}
			if (inputFields.city || inputFields['postal-code']) {
				if (inputFields.street) { address += ','; }
				address += (inputFields['postal-code'] ? ' ' + inputFields['postal-code'] : '') + (inputFields.city ? ' ' + inputFields.city : '');
				address.trim();
			}

			// Nastavit adresu
			this.setAttribute('data-address', address);

			if (inputFields.latitude && inputFields.longitude) {
				// Clear the GPS when the address is changed
				inputFields.latitude = '';
				inputFields.longitude = '';
				changeGPS(null);
				// this.setAttribute('data-gps', inputFields.latitude + ', ' + inputFields.longitude);
				// changeLocation({
				// 	lat: parseFloat(inputFields.latitude),
				// 	lng: parseFloat(inputFields.longitude),
				// });
			}
			else {
				this.setAttribute('data-gps', '');
				geocodeAddress(address, 'changeLocation');
			}
		});

		// Autocomplete adresy u editace pobocky
		if (document.getElementById('branches-edit')) {
			initAutocomplete();
			document.querySelectorAll('.choices-multiple--pos-edit').forEach(item => {
			// Multiselect handler - vytvori z obycejneho selectu (u kazdeho bloku v segmentaci) spec. multiselect
				new Choices(item, {
					removeItemButton: true,
					itemSelectText: '',
					noChoicesText: 'Žádné možnosti k dispozici',
					noResultsText: 'Nebyly nalezeny žádné výsledky.',
					loadingText: 'Načítám...',
				});
			});
		}

		// Autocomplete adresy u editace pobocky
		function initAutocomplete () {
			var container = document.getElementById('branch-address');
			var input = container.querySelector('input[data-google-autocomplete]');
			var inputPlaceholder = input.placeholder;
			input.placeholder = 'Zadejte adresu';

			// Listener na fail u autentizace
			/* eslint-disable camelcase */
			window.gm_authFailure = function () {
				/* eslint-enable camelcase */
				google.maps.event.clearInstanceListeners(input);
				input.disabled = false;
				input.placeholder = inputPlaceholder;
				input.style.backgroundImage = '';
				document.body.removeChild(document.querySelector('.pac-container'));
			};

			// Inicializace autocomplete
			var autocomplete = new google.maps.places.Autocomplete(input);
			autocomplete.setFields([ 'address_component' ]); // Omezit vysledky, setrime penizky
			autocomplete.addListener('place_changed', changeAddress);

			function changeAddress () {
				var results = autocomplete.getPlace();

				// Vytvorit objekty pro snazsi manipulaci s vysledky
				var address = {};
				for (var i = 0; i < results.address_components.length; i++) {
					address[results.address_components[i].types[0]] = results.address_components[i].long_name;
				}

				// Namapovat vysledky na inputy
				var inputFields = {
					street: (address.route || ''),
					'street-number': ((address.premise || '') + '/' + (address.street_number || '')).replace(/^\/+|\/+$/g, ''), // Trim "/"
					city: (address.locality || address.administrative_area_level_2 || ''),
					'postal-code': (address.postal_code || ''),
				};

				// Zmenit hodnoty vsech inputu
				for (var type in inputFields) {
					if (inputFields.hasOwnProperty(type)) {
						let AdressInput = container.querySelector('input[data-type="' + type + '"]');
						AdressInput.value = inputFields[type];

						// Odstranit pripadne error message, kdyz se inputy naplni
						/* global Nette */
						if (Nette.validateControl(AdressInput, null, false)) { removeErrorMessage(AdressInput); }
					}
				}

				// Zmenit adresu v mape
				document.getElementById('branch-map').dispatchEvent(new CustomEvent('changeAddress'));
			}
		}

		// Tlacitko pro ziskani GPS z adresy
		if (document.querySelector('#branch-address a.btn')) {
			document.querySelector('#branch-address a.btn').addEventListener('click', function (e) {
				e.preventDefault();
				geocodeAddress(document.getElementById('branch-map').getAttribute('data-address'), 'changeGPS');
			});
		}

		// Zmena GPS
		function changeGPS (location) {

			// Propsani GPS souradnic do inputu
			if (!location) {
				document.querySelector('#branch-address input[data-type="latitude"]').value = '';
				document.querySelector('#branch-address input[data-type="longitude"]').value = '';
			}
			else {
				document.querySelector('#branch-address input[data-type="latitude"]').value = location.lat();
				document.querySelector('#branch-address input[data-type="longitude"]').value = location.lng();

				// Zmena mapy
				document.getElementById('branch-map').setAttribute('data-gps', location.lat() + ', ' + location.lng());
				changeLocation(location);
			}
		}

		// Listener na zmenu v inputech
		document.querySelectorAll('#branch-address input').forEach(function (input) {
			input.addEventListener('change', function () {
				document.getElementById('branch-map').dispatchEvent(new CustomEvent('changeAddress'));
			});
		});
	};

	// Pridani Google Maps API
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = 'https://maps.googleapis.com/maps/api/js?key=' + window.googleMapsKey + '&libraries=places&callback=initMap';
	document.head.appendChild(script);
});


// Fotogalerie
document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('branches-gallery')) { return; }

	// Pokud neexistuji zadne fotky, nic nedelat
	if (document.querySelectorAll('#photo-thumbnails > div a').length == 0) { return; }

	// Pokud neni vybrana fotka, vybrat
	if (!document.querySelector('#photo-thumbnails > div a.selected')) {
		switchTo(document.querySelector('#photo-thumbnails > div a:first-of-type'));
	}

	// Funkce pro vybrani fotky
	function switchTo (a) {

		// Mobilni verze
		if (window.getComputedStyle(document.getElementById('photo-container')).display == 'none') {
			window.popup({
				type: 'image',
				url: a.href,
			});
			return;
		}

		// Desktopova verze
		if (document.querySelector('#photo-thumbnails > div a.selected')) {
			document.querySelector('#photo-thumbnails > div a.selected').classList.remove('selected');
		}
		a.classList.add('selected');

		document.querySelector('#photo-container img').src = a.href;
		document.querySelector('#photo-container a.enlarge').href = a.href;
	}

	// Prepinani fotek
	document.querySelectorAll('#photo-thumbnails > div a').forEach(function (a) {
		a.addEventListener('click', function (e) {
			e.preventDefault();
			switchTo(a);
		});
	});

	// Sipka vlevo
	document.querySelector('#photo-thumbnails > a.left').addEventListener('click', function (e) {
		e.preventDefault();

		var thumb = document.querySelector('#photo-thumbnails > div a.selected').previousElementSibling;
		if (!thumb) {
			thumb = document.querySelector('#photo-thumbnails > div a:last-of-type');
		}
		switchTo(thumb);
	});

	// Sipka vpravo
	document.querySelector('#photo-thumbnails > a.right').addEventListener('click', function (e) {
		e.preventDefault();

		var thumb = document.querySelector('#photo-thumbnails > div a.selected').nextElementSibling;
		if (!thumb) {
			thumb = document.querySelector('#photo-thumbnails > div a:first-of-type');
		}
		switchTo(thumb);
	});
});


// Fotogalerie - editace
document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('branches-gallery-edit')) { return; }

	// Smazani celeho boxu s fotkou pri kliknuti na odstraneni fotky
	window.addEventListener('photoRemoveFile', function (e) {
		e.target.parentNode.removeChild(e.target);
	});

	// Vlozit fotky pri vybrani/file drop
	var newPhotos = [];
	var addPhotoDiv = document.getElementById('add-gallery-photo');

	addPhotoDiv.querySelector('input').addEventListener('change', function () {
		addPhotoDiv.classList.remove('dragging');

		// Pridat fotky
		if (this.files.length > 0) {
			Array.prototype.forEach.call(this.files, function (file) {

				// Vlozit do pole
				newPhotos.push(file);

				// Zkopirovat sablonu
				var photoTemplate = document.getElementById('gallery-photo-template').cloneNode(true);
				photoTemplate.id = '';
				addPhotoDiv.parentNode.insertBefore(photoTemplate, addPhotoDiv);

				// Inicializovat funkcionalitu fotky
				var photo = new Photo(photoTemplate, file);

				// Listener na odstraneni souboru
				photo.container.addEventListener('photoRemoveFile', function () {
					newPhotos = newPhotos.filter(function (f) {
						return f != file;
					});
				});

				// Nahrat fotku na server
				var url = (document.querySelector('#branches-gallery-edit form').getAttribute('action') || window.location.href);
				var method = (document.querySelector('#branches-gallery-edit form').getAttribute('method') || 'POST');

				// Specialne pro momentalni backendove zpracovani
				var dataToAppend = [];
				var inputDo = document.querySelector('input[name="_do"]');
				if (inputDo) {
					dataToAppend.push({
						key: inputDo.getAttribute('name'),
						value: inputDo.value,
					});
				}

				photo.uploadFile(url, method, dataToAppend);
			});
		}

		// Vyprazdnit input
		this.value = '';
		if (this.value) {
			this.type = 'text';
			this.type = 'file';
		}
	});

	// Listener na kliknuti na submit tlacitko
	naja.addEventListener('interaction', function (e) {

		// Byla udalost vyvolana submit buttonem?
		if (e.element == document.querySelector('#branches-gallery-edit button[type="submit"]')) {

			// Zkontrolovat, zdali jsou vsechny nove fotky nahrane
			for (var i = 0; i < newPhotos.length; i++) {
				if (!newPhotos[i].uploaded) {
					new TempMessage({
						html: 'Před uložením galerie prosím vyčkejte, než se fotky nahrají.',
						type: 'warning',
					});

					e.preventDefault();
					return false;
				}
			}

			// Odstranit moznost nahravat nove fotky
			addPhotoDiv.parentNode.removeChild(document.getElementById('gallery-photo-template'));
			addPhotoDiv.querySelector('input').parentNode.removeChild(addPhotoDiv.querySelector('input'));
		}
	});

	// Detekce file dragging
	var dragTimer; // Prevence flickering

	document.addEventListener('dragover', function (e) {
		if (e.dataTransfer.types.indexOf('Files') == -1) { return; }

		addPhotoDiv.classList.add('dragging');
		window.clearTimeout(dragTimer);
	});
	document.addEventListener('dragleave', function (e) {
		if (e.dataTransfer.types.indexOf('Files') == -1) { return; }

		dragTimer = setTimeout(function () {
			addPhotoDiv.classList.remove('dragging');
		}, 25);
	});

	// Razeni fotek
	dragula({
		containers: [ document.querySelector('#branches-gallery-edit fieldset:first-of-type') ],
		direction: 'horizontal',

		// Zamezit dragovani upload tlacitka
		invalid: function (el) {
			return el.id == 'add-gallery-photo';
		},

		// Zamezit dragovani za upload tlacitko
		accepts: function (el, target, source, sibling) {
			if (sibling === null || sibling.id == 'gallery-photo-template') { return false; }
			else { return true; }
		},
	});
});


document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('branches-edit')) { return; }

	let inputId = document.querySelector('[name="customId"]');
	let checkbox = document.querySelector('[name="customRequired"]') ? document.querySelector('[name="customRequired"]').parentElement : null;

	// Disable checkboxu pro generovani ID, pokud je ID vyse v inputu vyplnene
	if (inputId && checkbox) {
		if (inputId.value) {
			checkbox.classList.add('disabled');
			checkbox.querySelector('input').checked = false;
		}
		else { checkbox.classList.remove('disabled'); }

		inputId.addEventListener('keyup', () => {
			if (inputId.value) {
				checkbox.classList.add('disabled');
				checkbox.querySelector('input').checked = false;
			}
			else { checkbox.classList.remove('disabled'); }
		});
	}


	// Propojeni switcheru v opening hours
	function toggleSwitch () {
		document.querySelectorAll('[data-state]').forEach(e => { toggleAttrValue(e, 'closed', 'open'); });
		document.querySelectorAll('[data-state] .switch input').forEach(elem => { toggleAttr(elem, 'checked'); });
	}

	// funkce pro toggle hodnoty jakehokoli atributu
	function toggleAttrValue (elem, stateOne, stateTwo) {
		var el = typeof elem === 'string' ? document.querySelector(elem) : elem;
		el.setAttribute('data-state', el.getAttribute('data-state') === stateTwo ? stateOne : stateTwo);
	}

	// funkce pro toggle jakehokoli atributu (bez hodnoty)
	function toggleAttr (elem, state) {
		var el = typeof elem === 'string' ? document.querySelector(elem) : elem;
		el.hasAttribute(state) ? el.removeAttribute(state) : el.setAttribute(state, '');
	}

	document.querySelectorAll('[data-state] .switch input').forEach(el => el.addEventListener('click', toggleSwitch));

	// Inicializace switcheru
	naja.afterUpdateFunctions.push(function () {
		document.querySelectorAll('[data-state] .switch input').forEach(el => el.addEventListener('click', toggleSwitch));
	});

	// Prejit na mapu funkcionalita - scroll dolu
	var toMapBtn = document.querySelector('button[name="toMap"]');
	var mapPosition = document.getElementById('branches-edit').scrollHeight;

	if (toMapBtn) { toMapBtn.addEventListener('click', () => { window.scrollTo({ top: mapPosition, behavior: 'smooth' }); }); }
});

// Inventory functionality
document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('branches-marketing') && !document.getElementById('stock')) { return; }

	// Add title to Reklamované nosiče items
	document.querySelectorAll('.reklamace').forEach(el => {
		let count = el.querySelector('.pieces').innerHTML;
		el.setAttribute('title', 'Tento POSM byl reklamován (celkem ' + count + ').');
	});

});
